import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {domainUrl} from '../../helper';
import Image from "next/image";
import departmentsAria from "../../services/departmentsArea";
import {ISlideImages, IState} from "../../types/state";
import {withHooksHOC} from "../../hooks/useWindowWidth";
import {setWindowSize} from "../../store/general";
import StroykaSlick from "../shared/StroykaSlick"
import {slickSettings} from "../../services/breakpoints";
import cls from "./BlockSlideShow.module.scss"

interface IBlockSlideShowProps {
    media?: MediaQueryList,
    dbName: string,
    withDepartments?: boolean
    width?: number
}

const BlockSlideShow = (props: IBlockSlideShowProps) => {
    // @ts-ignore
    const {dbName, withDepartments = false, width, sliderData} = props
    // const {dbName,withDepartments} = props

    const [media, setMedia] = useState<any>(null)
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    const slideImages = sliderData.sliders
    const locale = useSelector((state: IState) => state.locale)

    let departmentsAreaRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 991)
    }, [windowSize])

    // useEffect(() => {
    //
    //     setMedia(window.matchMedia("(min-width: 992px)"));
    //
    //     if (media.addEventListener) {
    //         media.addEventListener("change", onChangeMedia);
    //     } else {
    //         media.addListener(onChangeMedia);
    //     }
    //
    //     return () => {
    //         departmentsAria.area = null;
    //
    //         if (!media.removeEventListener) {
    //             media.removeListener(onChangeMedia);
    //         } else {
    //             media.removeEventListener("change", onChangeMedia);
    //         }
    //     }
    //
    // }, [])

    // const onChangeMedia = () => {
    //
    //     if (media.matches) {
    //         departmentsAria.area = departmentsAreaRef;
    //     }
    //
    // }
    const setDepartmentsAreaRef = (ref: HTMLDivElement) => {
        // @ts-ignore
        departmentsAreaRef.current = ref;

        if (media.matches) {
            // @ts-ignore
            departmentsAria.area = departmentsAreaRef;
        }
    };

    const openNewTabClickBuyNow = (path: any) => {
        window.open(path, "_blank");
    }

    // cls

    const slideClasses = classNames(cls["block-slideshow__slide-image"], {
        [cls["block-slideshow__slide-image--desktop"]]: !isMobile,
        [cls["block-slideshow__slide-image--mobile"]]: isMobile,
    });

    const drawRightSlider = (path: string) => {
        return (
            <div className={slideClasses}>
                <Image
                    src={domainUrl(`${dbName}/storage/${path}`)}
                    alt="zega slide"
                    placeholder="blur"
                    layout="fill"
                    priority
                    blurDataURL={`/zega/blur1.webp`}
                />
            </div>
        )
    }

    const slides = slideImages?.map((slide: ISlideImages, index: number) => {
        const image = slide.path;
        const MobileImage = slide.mobile_path;
        let realPath = !isMobile ? image : MobileImage

        return (
            // slide.locale.split(",").includes(locale.code) && (

                <div key={index} className={cls["block-slideshow__slide"]}>

                    {drawRightSlider(realPath)}

                    <div className={cls["block-slideshow__slide-content"]}>
                        <div
                            className={cls["block-slideshow__slide-title"]}
                            dangerouslySetInnerHTML={{__html: slide.content}}
                        />

                        {/*<div*/}
                        {/*    className="block-slideshow__slide-text"*/}
                        {/*    dangerouslySetInnerHTML={{__html: slide.text}}*/}
                        {/*/>*/}
                    </div>

                    {slide.slider_path.length > 0 && (
                        <div className={cls["block-slideshow__slide-button"]}>
                            <button
                                onClick={() => openNewTabClickBuyNow(slide.slider_path)}
                                // FIXME:fix when _global.scss will ready
                                className={`btn btn-orange ${cls["slideshow-btn"]}`}
                            >
                                <FormattedMessage
                                    id="slideshow-btn"
                                    defaultMessage="Buy now"
                                />
                            </button>
                        </div>
                    )}
                </div>
            // )
        );
    });


    const blockClasses = classNames(cls["block-slideshow"], {
        [cls["block-slideshow--layout--full"]]: !withDepartments,
        [cls["block-slideshow--layout--with-departments"]]: withDepartments,
    });

    return (
        <div className={cls["home_slide_container"]}>
            <div className={blockClasses}>
                <>
                    {withDepartments && (
                        <div
                            // FIXME:fix when _global.scss will ready
                            className="absolute col-3 d-lg-block d-none"
                            ref={setDepartmentsAreaRef}
                        />
                    )}
                    <div className={cls[`block-slideshow__body`]}>
                        {/*// @ts-ignore*/}
                        <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                    </div>
                </>
            </div>
        </div>
    )
}

export default withHooksHOC(BlockSlideShow)
